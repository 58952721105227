(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-router-dom"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("opentok-ux-components", ["react", "react-router-dom", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["opentok-ux-components"] = factory(require("react"), require("react-router-dom"), require("react-dom"));
	else
		root["opentok-ux-components"] = factory(root["react"], root["react-router-dom"], root["react-dom"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_router_dom__, __WEBPACK_EXTERNAL_MODULE_react_dom__) {
return 
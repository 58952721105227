import React from "react";
import { Button, ButtonTypes, CardTopCategory } from "../index";
import {
  formatPrice,
  getFreeShippingThreshold,
  isBlackFriday,
} from "../../utils";

import arrowRight from "../../assets/arrowOrder.png";
import styles from "./MiniCart.css";

type MiniCartProps = {
  items?: any;
  error?: any;
  closeSidebar?: any;
  miniCartProducts?: any;
  contextKey?: any;
  contextItemId?: any;
  removeContextKey?: any;
  menu?: any;
};

function MiniCart({
  error,
  items,
  menu,
  closeSidebar,
  miniCartProducts,
  contextKey,
  contextItemId,
  removeContextKey,
}: MiniCartProps) {
  const reducer = (accumulator: any, currentValue: any) =>
    accumulator + currentValue;
  const productPrice = items.map((item: any) => item.rowTotalInclTax);
  const priceTotal = productPrice.reduce(reducer, 0);
  const totalItems = items.map((item: any) => item.qty);
  const totalItemsLength = totalItems.reduce(reducer, 0);
  const freeShippingThreshold = getFreeShippingThreshold();

  const thresolds = [
    {
      amount: freeShippingThreshold,
      message: "Plus que %remaining% € pour bénéficier de la livraison offerte",
    },
  ];

  if (isBlackFriday()) {
    thresolds.push({
      amount: 100,
      message: "Encore %remaining% € pour profiter de -5% sur votre commande",
    });
    thresolds.push({
      amount: 199,
      message:
        "Plus que %remaining% € pour profiter de -10% sur votre commande",
    });
  }

  const nextThreshold = thresolds.find(
    (threshold) => priceTotal < threshold.amount
  );

  return (
    <>
      {contextKey != "addError" ? (
        <>
          <>
            <div className={styles.headerMiniCart}>
              <div onClick={() => closeSidebar()}>
                <img
                  src={arrowRight}
                  alt={"back"}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <span className={styles.itemLength}>
                {contextItemId
                  ? "Cet article a bien été ajouté au panier"
                  : "Votre panier" +
                    (!items.length
                      ? " est vide"
                      : " - " + totalItemsLength + " articles")}
              </span>
            </div>
            <div className={styles.divider} />
          </>
          {miniCartProducts}
          {!items.length && (
            <div className={styles.topCategories}>
              {menu.menu[0].children.map((category: any) => (
                <CardTopCategory category={category} key={`c-${category.id}`} />
              ))}
            </div>
          )}
        </>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div onClick={() => closeSidebar()}>
              <img
                src={arrowRight}
                alt={"back"}
                style={{ cursor: "pointer" }}
              />
            </div>
            <span style={{ color: "red" }}>Une erreur s'est produite</span>
          </div>
          <div className={styles.separator} />
          {error === "EXCEED_MAX_ITEM_QTY" && (
            <p>
              Vous avez atteint la quantité maximale autorisée de ce produit
              dans votre panier.
            </p>
          )}
          {error === "STOCK_TOO_LOW" && (
            <p>
              Malheureusement, ce produit n'est plus disponible dans la quantité
              demandée.
            </p>
          )}
          {error === "OUT_OF_STOCK" && (
            <p>
              Nous sommes désolés, le stock de ce produit est actuellement
              épuisé.
            </p>
          )}
        </div>
      )}
      {items.length > 0 && (
        <div className={styles.bottomOrder}>
          <div className={styles.blockWithProgress}>
            {priceTotal >= freeShippingThreshold && (
              <span style={{ marginBottom: "24px" }}>
                Vous bénéficiez de la <b>livraison offerte</b> !
              </span>
            )}
            {nextThreshold && (
              <>
                <span className={styles.upToFreeDelivery}>
                  {nextThreshold.message.replace(
                    "%remaining%",
                    formatPrice(nextThreshold.amount - priceTotal)
                  )}
                </span>
                <progress max={nextThreshold.amount} value={priceTotal} />
              </>
            )}
          </div>
          {contextKey != "addSuccess" ? (
            <div className={styles.firstBottomBlock}>
              <div className={styles.buttonOrder}>
                <Button
                  text={"Commander"}
                  onClick={closeSidebar}
                  type={ButtonTypes.Primary}
                  href={"/commande"}
                  style={{ width: "100%" }}
                  as={"link"}
                />
                <span style={{ marginTop: "12px", display: "block" }}>
                  Un code de réduction ? C'est juste après !
                </span>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.blockButton}>
                <Button
                  text={"Continuer mes achats"}
                  onClick={closeSidebar}
                  as="link"
                  type={ButtonTypes.Tertiary}
                />
                <Button
                  text={"Mon Panier (" + totalItemsLength + ")"}
                  onClick={removeContextKey}
                  as={"button"}
                  type={ButtonTypes.Secondary}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default MiniCart;

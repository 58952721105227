import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import { formatPrice, isBlackFriday } from "../../utils";

import styles from "./card.css";

type CardProps = {
  isConfigurable: boolean;
  item?: any;
  categoryName?: string;
  imgLazyLoading?: boolean;
};

function isNew(item: any) {
  if (!item || !item.attributes) return false;

  const newsToDate = item.attributes.find(
    (attr: any) => attr.attributeCode === "newsToDate" && attr.value.length > 0
  );
  const newsFromDate = item.attributes.find(
    (attr: any) =>
      attr.attributeCode === "newsFromDate" && attr.value.length > 0
  );

  if (!newsToDate && !newsFromDate) return false;

  return (
    (!newsToDate || new Date(newsToDate.value) > new Date()) &&
    (!newsFromDate || new Date(newsFromDate.value) < new Date())
  );
}

function isGreen(item: any) {
  if (!item || !item.attributes) return false;

  const green = item.attributes.findIndex(
    (attr: any) => attr.attributeCode === "greenBeauty" && attr.value === "1"
  );

  return green > -1;
}

function Card({
  isConfigurable,
  item,
  categoryName,
  imgLazyLoading = false,
}: CardProps) {
  const getPrice = (type: string) => {
    if (item.configurableOptions && item.configurableOptions.length > 0) {
      // Item is a configurable, get lowest price from values
      let cheapestItem = item.configurableOptions[0].values.reduce(
        (prev: any, curr: any) => {
          return prev.price[type] < curr.price[type] ||
            curr.price[type] === null
            ? prev
            : curr;
        }
      );
      return cheapestItem;
    } else {
      // Item is a simple
      return item;
    }
  };

  //const TagImgAlt = item.name + ' ' + item.brandName + ' ' + item.shortDescription;

  const imgAlt =
    (categoryName
      ? categoryName
      : item.name + " " + item.brandName + " " + item.shortDescription) +
    " pas cher";
  const cheapestItemForRegularPrice = getPrice("regular");
  const cheapestItemForSpecialPrice = getPrice("special");

  let price = cheapestItemForRegularPrice.price["regular"];
  let specialPrice = cheapestItemForRegularPrice.price["special"];

  if (
    cheapestItemForSpecialPrice.price["special"] !== null &&
    cheapestItemForSpecialPrice.price["special"] < price
  ) {
    price = cheapestItemForSpecialPrice.price["regular"];
    specialPrice = cheapestItemForSpecialPrice.price["special"];
  }

  const hasSpecialPrice = specialPrice > 0 && specialPrice < price;
  const displayBlackFridayBadge = false; //isBlackFriday() && item.promotionalBadge;

  return (
    <Link
      to={item.urlPath}
      className={cx(styles.card, {
        transparent: item.brandName.toLowerCase() === "dior",
      })}
    >
      {item && isNew(item) && !displayBlackFridayBadge && (
        <span className={styles.isNewBadge}>Nouveauté</span>
      )}
      {item && (item.promotionalBadge || isGreen(item)) && (
        <div className={styles.badges}>
          {item && item.promotionalBadge && (
            <span className={styles.promotionalBadge}>
              {item.promotionalBadge}
            </span>
          )}
          {isGreen(item) && <span className={styles.greenBadge} />}
        </div>
      )}
      {displayBlackFridayBadge && (
        <span className={styles.blackFridayBadge}>Black Friday</span>
      )}
      <img
        src={item.thumbnail}
        className={styles.cardImg}
        alt={imgAlt}
        loading={imgLazyLoading ? "lazy" : undefined}
        width="364"
        height="524"
      />
      <div className={styles.wrapper}>
        <h3>
          <span className={styles.brand}>{item.brandName}&nbsp;</span>
          <span className={styles.name}>{item.name}&nbsp;</span>
          <span className={styles.shortDescription}>
            {item.shortDescription}
          </span>
        </h3>
        <div className={styles.contentPrice}>
          {hasSpecialPrice && (
            <span className={styles.specialPrice}>
              {isConfigurable && <span>à partir de&nbsp;</span>}
              {formatPrice(specialPrice)} €
            </span>
          )}
          <span
            className={
              hasSpecialPrice ? styles.cardSpecialPrice : styles.cardPrice
            }
          >
            {isConfigurable && !hasSpecialPrice && (
              <span>à partir de&nbsp;</span>
            )}
            {formatPrice(price)} €
          </span>
        </div>
      </div>
    </Link>
  );
}

export default Card;

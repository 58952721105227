import React from "react";
import { Box, Label, extractThemableProps, themed } from "@deity/falcon-ui";
import {
  Field,
  getDefaultInputTypeValidator,
  requiredValidator,
  toGridTemplate
} from "@deity/falcon-ecommerce-uikit";
//import { AddressAutofill } from "@mapbox/search-js-react";
export const FormFieldArea = {
  label: "label",
  input: "input",
  error: "error"
};
import "./SignIn.css";
import { Input, InputPassword } from "benlux-ecommerce-ui";

export const FormFieldLayout = themed({
  tag: Box,
  defaultTheme: {
    formFieldLayout: {
      display: "grid",
      gridGap: "xs",
      // prettier-ignore
      gridTemplate: toGridTemplate([
                ['1fr'                     ],
                [FormFieldArea.label       ],
                [FormFieldArea.input       ],
                [FormFieldArea.error, '0px']
            ])
    }
  }
});

export const FormFieldLabel = themed({
  tag: Label,
  defaultProps: {
    gridArea: FormFieldArea.label
  }
});

export const FormFieldError = themed({
  tag: Box,
  defaultProps: {
    gridArea: FormFieldArea.error
  },
  defaultTheme: {
    formFieldError: {
      gridArea: FormFieldArea.error,
      color: "error",
      fontSize: "xxs",
      css: {
        pointerEvents: "none",
        justifySelf: "end"
      }
    }
  }
});

export const FormAddressField = ({
  name,
  label,
  displayedLabel,
  validate,
  required,
  placeholder,
  children,
  errorMessage,
  type,
  ...restProps
}) => {
  const { rest } = extractThemableProps(restProps);

  // eslint-disable-next-line
  let validators = validate || [];
  if (required) {
    validators.unshift(requiredValidator);
  }
  const defaultInputTypeValidator =
    !validate && getDefaultInputTypeValidator(restProps.type);
  if (defaultInputTypeValidator) {
    validators.push(defaultInputTypeValidator);
  }

  if (typeof document === "undefined") {
    return (
      <Field name={name} validate={validators} {...rest}>
        {({ form, field, label, error }) => {
          return (
            <div>
              <Input
                {...field}
                label={displayedLabel}
                required={required}
                name={name}
                type={type}
                placeholder={placeholder}
                style={{ borderColor: error ? "#EF394F" : "initial" }}
              />
              <FormFieldError>
                {error ? "Veuillez renseigner ce champ" : null}
              </FormFieldError>
            </div>
          );
        }}
      </Field>
    );
  }

  const AddressAutofill = require("@mapbox/search-js-react").AddressAutofill;

  return (
    <Field name={name} validate={validators} {...rest}>
      {({ form, field, label, error }) => {
        return (
          <div>
            <AddressAutofill accessToken="pk.eyJ1IjoiYmVubHV4c3lzZW50aXZlIiwiYSI6ImNsbnNyOTU5eTB2YncyanBjbDRyY3Z3aXcifQ.rlml3t92GAv4k-ZLkVdl8g">
              <Input
                onBlur={field.onBlur}
                onChange={e => {
                  form.setFieldValue(name, e.target.value);
                }}
                label={displayedLabel}
                required={required}
                name={name}
                type={type}
                placeholder={placeholder}
                style={{ borderColor: error ? "#EF394F" : "initial" }}
              />
            </AddressAutofill>
            <FormFieldError>
              {error ? "Veuillez renseigner ce champ" : null}
            </FormFieldError>
          </div>
        );
      }}
    </Field>
  );
};

export const FormField = ({
  name,
  label,
  displayedLabel,
  validate,
  required,
  placeholder,
  children,
  errorMessage,
  type,
  onChange,
  ...restProps
}) => {
  const { rest } = extractThemableProps(restProps);

  // eslint-disable-next-line
  let validators = validate || [];
  if (required) {
    validators.unshift(requiredValidator);
  }
  const defaultInputTypeValidator =
    !validate && getDefaultInputTypeValidator(restProps.type);
  if (defaultInputTypeValidator) {
    validators.push(defaultInputTypeValidator);
  }

  return (
    <Field name={name} validate={validators} {...rest} onChange={onChange}>
      {({ form, field, label, error }) => {
        return (
          <div>
            <Input
              {...field}
              label={displayedLabel}
              required={required}
              name={name}
              type={type}
              placeholder={placeholder}
              style={{ borderColor: error ? "#EF394F" : "initial" }}
            />
            <FormFieldError>
              {error
                ? error.indexOf("required") > -1
                  ? "Veuillez renseigner ce champ"
                  : error
                : null}
            </FormFieldError>
          </div>
        );
      }}
    </Field>
  );
};

export const FormFieldPassword = ({
  name,
  validate,
  required,
  placeholder,
  children,
  type,
  passwordValidator,
  errorMessage,
  validPassword,
  onChange,
  ...restProps
}) => {
  const { rest } = extractThemableProps(restProps);

  // eslint-disable-next-line
  let validators = validate || [];
  if (required) {
    validators.unshift(requiredValidator);
  }
  const defaultInputTypeValidator =
    !validate && getDefaultInputTypeValidator(restProps.type);
  if (defaultInputTypeValidator) {
    validators.push(defaultInputTypeValidator);
  }

  return (
    <Field name={name} {...rest}>
      {({ form, field, label, error }) => {
        return (
          <div>
            <InputPassword
              {...field}
              name={name}
              placeholder={placeholder}
              validPassword={valid => validPassword(valid)}
              passwordValidator={passwordValidator}
            />
            {/*<PasswordCheck*/}
            {/*  rules={["length","number","capital"]}*/}
            {/*  minLength={8}*/}
            {/*  value={field.value}*/}
            {/*  onChange={(isValid) => console.log({isValid})}*/}
            {/*  validColor={"#4BCA81"}*/}
            {/*/>*/}
            <FormFieldError>
              {field.invalid ? errorMessage : null}
            </FormFieldError>
          </div>
        );
      }}
    </Field>
  );
};

import formatPrice from "./formatPrice";
import { getPath } from "./getPath";

function isBlackFriday() {
  const bfStartTime = new Date("2024-11-22 00:00").getTime() / 1000;
  const bfEndTime = new Date("2024-12-13 00:00").getTime() / 1000;

  const nowTime = new Date().getTime() / 1000;
  return nowTime > bfStartTime && nowTime < bfEndTime;
}

function getShippingOfferEndTime() {
  return new Date("2024-12-02 00:00").getTime() / 1000;
}
function isShippingOfferActive() {
  const startTime = new Date("2024-11-29 00:00").getTime() / 1000;
  const endTime = getShippingOfferEndTime();
  const nowTime = new Date().getTime() / 1000;

  return nowTime > startTime && nowTime < endTime;
}

function getFreeShippingThreshold() {
  return isShippingOfferActive() ? 25 : 60;
}

export {
  formatPrice,
  getPath,
  isBlackFriday,
  getFreeShippingThreshold,
  isShippingOfferActive,
  getShippingOfferEndTime,
};

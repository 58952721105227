import React, { useEffect, useRef, useCallback, useState } from "react";
import cx from "classnames";
import moment from "moment";

import * as styles from "./FreeShippingPopIn.css";
import { Button, ButtonTypes } from "../Button";

import { getShippingOfferEndTime, isShippingOfferActive } from "../../utils";

const calculateDuration = (eventTime: number) =>
  moment.duration(
    Math.max(eventTime - Math.floor(Date.now() / 1000), 0),
    "seconds"
  );

function Countdown({
  eventTime,
  interval,
}: {
  eventTime: number;
  interval: number;
}) {
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  const hours = duration.days() * 24 + duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return (
    <div
      style={{
        display: "flex",
        marginBottom: "38px",
        marginTop: "20px",
        justifyContent: "center",
      }}
    >
      <div>
        <div style={{ textAlign: "center" }}>
          <span className={styles.digits}>
            {hours > 9 ? hours.toString()[0] : "0"}
          </span>
          <span className={styles.digits}>
            {hours > 9 ? hours.toString()[1] : hours.toString()[0]}
          </span>
        </div>
        <div className={styles.units}>heures</div>
      </div>
      <div className={styles.timeSeparator}>:</div>
      <div>
        <div style={{ textAlign: "center" }}>
          <span className={styles.digits}>
            {minutes > 9 ? minutes.toString()[0] : "0"}
          </span>
          <span className={styles.digits}>
            {minutes > 9 ? minutes.toString()[1] : minutes.toString()[0]}
          </span>
        </div>
        <div className={styles.units}>minutes</div>
      </div>
      <div className={styles.timeSeparator}>:</div>
      <div>
        <div style={{ textAlign: "center" }}>
          <span className={styles.digits}>
            {seconds > 9 ? seconds.toString()[0] : "0"}
          </span>
          <span className={styles.digits}>
            {seconds > 9 ? seconds.toString()[1] : seconds.toString()[0]}
          </span>
        </div>
        <div className={styles.units}>secondes</div>
      </div>
    </div>
  );
}

function FreeShippingPopIn() {
  const [visible, setVisible] = useState(true);

  const onClose = () => {
    setVisible(false);
  };

  const isActive = isShippingOfferActive();
  const endTime = getShippingOfferEndTime();

  // hide popin if not in time range
  if (!isActive) {
    return null;
  }

  return (
    <>
      <div className={cx(styles.modal, { visible: visible })} role="alert">
        <div className={styles.cross} onClick={onClose} role="button" />
        <div className={styles.content}>
          <span className={styles.title}>BLACK FRIDAY</span>
          <div className={styles.description}>
            <span>Livraison offerte tout le week-end</span>
            <br />
            <span>dès 25€ d'achat</span>
          </div>
          <Countdown eventTime={endTime} interval={1000} />
          <Button
            text={"J'en profite"}
            type={ButtonTypes.Primary}
            as={"link"}
            href={"/black-friday"}
            onClick={onClose}
            style={{ backgroundColor: "#FFF", color: "#000", borderRadius: 0 }}
          />
        </div>
      </div>
      <div
        className={cx(styles.modalBackDrop, { visible: visible })}
        onClick={onClose}
      />
    </>
  );
}

export default FreeShippingPopIn;

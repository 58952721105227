const getDeliveryDate = (attributeSetName: string) => {
  const processingDurationDays = attributeSetName === "Watch" ? 7 : 0;
  const processingDays = [1, 3, 5];
  const lateProcessingHour = 12;
  const carrierWorkingDays = [1, 2, 3, 4, 5, 6];
  const carrierDurationDays = 2;
  const oneDay = 24 * 60 * 60 * 1000;

  // Start date
  const now = new Date(new Date().getTime() + processingDurationDays * oneDay);

  // 1. Calculate shipping (when order will leave BENLUX)
  let shippingDate = new Date(now.getTime());

  if (
    processingDays.indexOf(now.getDay()) === -1 ||
    now.getHours() >= lateProcessingHour
  ) {
    shippingDate.setDate(shippingDate.getDate() + 1);

    // Find next processing day
    while (processingDays.indexOf(shippingDate.getDay()) === -1) {
      shippingDate.setDate(shippingDate.getDate() + 1);
    }
  }

  // 2. Calculate delivery date based on carrier duration and carrier working days
  let durationDaysToDelivery = carrierDurationDays;
  let deliveryDate = new Date(shippingDate.getTime());

  while (durationDaysToDelivery > 0) {
    deliveryDate.setDate(deliveryDate.getDate() + 1);

    if (carrierWorkingDays.indexOf(deliveryDate.getDay()) != -1) {
      durationDaysToDelivery -= 1;
    }
  }

  return deliveryDate;
};

export default getDeliveryDate;

import React from "react";
import moment from "moment";

import getDeliveryDate from "../../utils/getDeliveryDate";

import withdrawal from "../../assets/withdrawal.png";
import deliveryproduct from "../../assets/deliveryProduct.png";
import trustpilotStar from "../../assets/trustpilot-star.png";
import lock from "../../assets/lock.png";
import americanExpress from "../../assets/American Express.svg";
import mastercard from "../../assets/Mastercard.svg";
import visa from "../../assets/Visa.svg";
import paypal from "../../assets/PayPal.svg";

import { Button, ButtonTypes } from "../index";
import styles from "./ProductInfoAddCart.css";

import { getFreeShippingThreshold } from "../../utils";

type ProductProps = {
  product: any;
  onChangeQty: (qty: number) => void;
  disabledButton?: boolean;
  selectedValueIndex?: any;
  trustpilotData: {
    profileUrl: string;
    ratingCount: number;
    ratingValue: number;
    bestRating: number;
    starsString: string;
    stars: number;
  };
};

interface DeliveryDateProps {
  attributeSetName: string;
}

function DeliveryDate({ attributeSetName }: Readonly<DeliveryDateProps>) {
  const deliveryDate = getDeliveryDate(attributeSetName);
  const dayName = moment(deliveryDate).format("dddd");
  const day = moment(deliveryDate).format("D");
  const month = moment(deliveryDate).format("MMMM");

  return (
    <p
      className={styles.reinsuranceFreeDelivery}
      style={{ color: "#4EB000", fontWeight: "bold" }}
    >
      <span style={{ textTransform: "capitalize" }}>{dayName}</span> {day}{" "}
      {month}
    </p>
  );
}

type GiftWithPurchaseBlockProps = {
  readonly title?: string;
  readonly description: string;
  readonly image: string;
};

const GiftWithPurchaseBlock = ({
  title = "Un cadeau dans votre panier !",
  description,
  image,
}: GiftWithPurchaseBlockProps) => (
  <div className={styles.gwpBlock}>
    <img src={image} alt="" />
    <div className={styles.gwpBlockText}>
      <p className={styles.gwpBlockTitle}>{title}</p>
      <p className={styles.gwpBlockDescription}>{description}</p>
    </div>
  </div>
);

function ProductInfoAddCart({
  product,
  selectedValueIndex,
  disabledButton,
  trustpilotData,
}: Readonly<ProductProps>) {
  const price =
    product.configurableOptions.length > 0
      ? product.configurableOptions[0].values[selectedValueIndex].price
      : product.price;
  const isWatch = product.attributeSetName === "Watch";
  const salePrice = price.special ? price.special : price.regular;
  const freeShippingThreshold = getFreeShippingThreshold();

  return (
    <div className={styles.blockBottom}>
      <div className={styles.blockButtons}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "360px",
            width: "100%",
          }}
        >
          <Button
            style={{ width: "100%" }}
            type={ButtonTypes.Primary}
            as={"submit"}
            loading={disabledButton}
            text={`Ajouter au panier`}
          />
          <div
            style={{
              fontSize: "11px",
              marginTop: "12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={paypal} alt="" />
            <span style={{ marginLeft: "4px" }}>
              Payez en{" "}
              <span
                style={{
                  display: "inline-block",
                  padding: "1px 3px",
                  background: "#000",
                  color: "#FFF",
                  borderRadius: "20px",
                }}
              >
                4x
              </span>{" "}
              <b>sans frais</b> avec PayPal
            </span>
          </div>
        </div>
      </div>
      {product.gwpBlock && (
        <GiftWithPurchaseBlock
          title={product.gwpBlock.title}
          description={product.gwpBlock.description}
          image={product.gwpBlock.giftImg}
        />
      )}
      <div className={styles.productReinsurance}>
        <div>
          <div className={styles.reinsuranceImg}>
            <img src={deliveryproduct} alt={"Livraison"} />
          </div>
          <div style={{ display: "block" }}>
            <div className={styles.title}>
              Livraison{salePrice > freeShippingThreshold ? " offerte " : " "}à
              domicile
            </div>
            <DeliveryDate attributeSetName={product.attributeSetName} />
          </div>
        </div>
        <div>
          <div className={styles.reinsuranceImg}>
            <img src={lock} alt={"Paiement sécurisé"} />
          </div>
          <div style={{ display: "block" }}>
            <div className={styles.title}>Paiement sécurisé</div>
            <div className={styles.paymentMethods}>
              <img src={visa} alt="" />
              <img src={mastercard} alt="" />
              <img src={americanExpress} alt="" />
              <img src={paypal} alt="" />
            </div>
          </div>
        </div>
        {trustpilotData && (
          <div>
            <a
              href={`${trustpilotData.profileUrl}?utm_medium=trustbox&utm_source=ProductReinsurance`}
              target="_blank"
              rel="noopener"
              style={{ display: "flex", textDecoration: "none" }}
            >
              <div className={styles.reinsuranceImg}>
                <img
                  src={trustpilotStar}
                  alt={"Avis vérifiés sur Trustpilot"}
                />
              </div>
              <div style={{ display: "block" }}>
                <div className={styles.title}>Benlux.fr sur Trustpilot</div>
                <p>
                  {trustpilotData.ratingValue.toString().replace(".", ",")} sur
                  5 ({trustpilotData.ratingCount}&nbsp;avis)
                </p>
              </div>
            </a>
          </div>
        )}
        <div>
          <div className={styles.reinsuranceImg}>
            <img src={withdrawal} alt={"Retrait en magasin"} />
          </div>
          <div style={{ display: "block" }}>
            <div className={styles.title}>Retrait en magasin</div>
            <p>Sous {isWatch ? "5 à 10 jours" : "2h"} (du lundi au jeudi)</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductInfoAddCart;

import React from "react";

import { getFreeShippingThreshold } from "../../utils";

import delivery from "../../assets/delivery.svg";
import turnback from "../../assets/turnback.svg";
import lock from "../../assets/lock.svg";
import canceling from "../../assets/canceling.svg";
import styles from "./FooterTop.css";

function FooterTop() {
  let freeShippingThreshold = getFreeShippingThreshold();

  return (
    <div className={styles.container}>
      <section className={styles.block}>
        <img src={delivery} alt={"Livraison"} />
        <span>
          Livraison offerte dès {freeShippingThreshold}&nbsp;€ d'achats
        </span>
      </section>
      <section className={styles.block}>
        <img src={lock} alt={"Paiement sécurisé"} />
        <span>
          Paiement sécurisé
          <br /> CB / PayPal / 4x sans frais
        </span>
      </section>
      <section className={styles.block}>
        <img src={turnback} alt={"turnback"} />
        <span>
          Faire&nbsp;un&nbsp;retour&nbsp;?
          C’est&nbsp;simple&nbsp;et&nbsp;rapide&nbsp;!
        </span>
      </section>
      <section className={styles.block}>
        <img src={canceling} alt={"canceling"} />
        <span>Retrait gratuit sous 2h dans notre magasin à Paris</span>
      </section>
    </div>
  );
}

export default FooterTop;
